<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>{{ !isReactivateInvoice ? 'Submit Tax Invoice' : 'Submit Re-activate Invoice' }}</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="field box">
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="filter.pageIndex"
          :page-size="filter.pageSize"
          :total-rows="totalRows"
          :is-loading="isTableLoading"
          :sort-column="filter.sortColumn"
          :sort-order="filter.sortOrder"
          :show-pagination="false"
          @pageChanged="onPageChange"
          @sort="sort">
          <tr v-for="(invoice, index) in invoices"
            :key="index">
            <td>{{ `${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}/${invoice.invoiceType}` }}</td>
            <td>{{ $filters.formatDateLocale(invoice.invoiceDate, $userInfo.locale) }}</td>
            <td>{{ value.quoteNo }}</td>
            <td class="has-text-right">{{ invoice.totalIncGst | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ invoice.gst | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ invoice.totalExGst | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-centered has-vertical-middle is-content-width">
              <div class="field">
                <div class="control">
                  <input class="is-checkradio is-primary"
                    :id="`orm-invoice-select${index}`"
                    :name="`orm-invoice-select${index}`"
                    type="checkbox"
                    v-model="invoice.isSelected"
                    @click="selectInvoice(invoice.invoiceId)">
                  <label :for="`orm-invoice-select${index}`"
                    data-label-empty />
                </div>
              </div>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </div>
      <div v-show="value.subQuoteNo > 0"
        class="field box">
        <label class="label">Invoice Reason</label>
        <textarea class="textarea"
          v-model="submitTaxInvoiceModel.invoiceReason"
          rows="3" />
      </div>
      <div v-show="isQuoteSubmitted || isInvoiceSubmitted"
        class="field box">
        <div class="control">
          <span v-show="isQuoteSubmitted"
            class="has-text-danger">
            Quote is in submitted stage. IT'S STRONGLY RECCOMMEND THAT YOU CHECK YOUR MAILBOX FOR THE EXPECTED AUTHORISATION MESSAGE AND LOAD IT INTO THIS QUOTE BEFORE YOU SEND TAX INVOICE
          </span>
          <span v-show="isInvoiceSubmitted"
            class="has-text-danger">
            Invoice is in submitted stage. YOU HAVE ALREADY SENT THE INVOICE FOR THIS QUOTE. IT'S STRONGLY RECCOMMEND THAT YOU RE-ACTIVATE THE TAX INVOICE
          </span>
        </div>
        <div v-show="isQuoteSubmitted || isInvoiceSubmitted"
          class="control">
          <input class="is-checkradio is-primary"
            :id="`orm-invoice-override`"
            :name="`orm-invoice-override`"
            type="checkbox"
            v-model="submitTaxInvoiceModel.isSendOverride">
          <label :for="`orm-invoice-override`">Proceed with sending this Invoice regardless</label>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="send()"
        data-tooltip="Send Tax Invoice"
        :disabled="!canSubmit">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Send</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import BulmaTable from '@/components/BulmaTable'
import { InvoiceTypes, OrmQuoteStatusTypes } from '@/enums'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { TableColumn } from '@/classes'
import _cloneDeep from 'lodash/cloneDeep'
import { OrmMessageService } from '../services'

export default {
  name: 'OrmSubmitTaxInvoiceModal',
  components: {
    BaseModalEx,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-send'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    isReactivateInvoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false, //this.active || false,
      innerValue: null,
      selectedRow: 0,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 20
      },
      isTableLoading: false,
      submitTaxInvoiceModel: {
        quoteId: this.value.quoteId,
        invoiceId: '',
        isSendOverride: false,
        isTotalMismatchOverride: false,
        invoiceReason: ''
      },
      invoices: _cloneDeep(this.value.invoices.filter(i => (i.invoiceType === InvoiceTypes.Quote || i.invoiceType === InvoiceTypes.Gst) && !i.deleted))
    }
  },
  validations: {},
  computed: {
    columns() {
      const c = [
        new TableColumn('Inv No.', 'invoiceNo', false, 'desc', 'left'),
        new TableColumn('Inv Date', 'invoiceDate', false, 'desc', 'left'),
        new TableColumn('Quote No.', 'quoteNo', false, 'desc', 'left'),
        new TableColumn('Total Inc. GST', 'totalIncGst', false, 'desc', 'right'),
        new TableColumn('GST', 'gst', false, 'desc', 'right'),
        new TableColumn('Total Excl. GST', 'totalExGst', false, 'desc', 'right'),
        new TableColumn('', '', false, 'desc', 'centered')
      ]
      return c
    },
    totalRows() {
      return this.invoices.length
    },
    canSubmit() {
      return (
        this.hasSelectedInvoice &&
        ((!this.isQuoteSubmitted && !this.isInvoiceSubmitted) || this.submitTaxInvoiceModel.isSendOverride) &&
        ((this.value.subQuoteNo > 0 && this.submitTaxInvoiceModel.invoiceReason) || this.value.subQuoteNo === 0)
      )
    },
    hasSelectedInvoice() {
      return this.invoices.some(i => i.isSelected)
    },
    isQuoteSubmitted() {
      return this.value.orm && this.value.orm.quoteStatusId === OrmQuoteStatusTypes.QuoteSubmitted
    },
    isInvoiceSubmitted() {
      return (
        this.value.orm &&
        (this.value.orm.quoteStatusId === OrmQuoteStatusTypes.QuoteInvoiceSubmitted ||
          this.value.orm.quoteStatusId === OrmQuoteStatusTypes.ReactiveInvoiceSubmitted)
      )
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    // Select a default invoice
    if (this.invoices.length === 1) {
      this.invoices[0].isSelected = true
    } else {
      const quoteInvoice = this.invoices.find(i => i.invoiceType === InvoiceTypes.Quote)
      quoteInvoice.isSelected = true
    }
    // this.isActive = true
  },
  mounted() {
    this.isActive = true
  },
  methods: {
    async send() {
      this.isActive = false
      this.$showSpinner('Submitting')
      this.submitTaxInvoiceModel.invoiceId = this.invoices.find(i => i.isSelected).invoiceId
      let result = ''
      if (!this.isReactivateInvoice) {
        result = await OrmMessageService.submitTaxInvoice(this.submitTaxInvoiceModel)
      } else {
        result = await OrmMessageService.submitReactiveTaxInvoice(this.submitTaxInvoiceModel)
      }
      console.log(result)
      this.$hideSpinner()
      this.$emit('update:active', false)
      this.$emit('close')
      this.$emit('sent')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    selectInvoice(invoiceId) {
      // console.log(invoiceId)
      this.invoices.forEach(function(invoice) {
        if (invoice.invoiceId !== invoiceId) {
          invoice.isSelected = false
        }
      })
    },
    onPageChange() {},
    sort() {}
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
