<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="filter.pageIndex"
          :page-size="filter.pageSize"
          :total-rows="totalRows"
          :is-loading="isTableLoading"
          :sort-column="filter.sortColumn"
          :sort-order="filter.sortOrder"
          @pageChanged="onPageChange"
          @sort="sort">
          <tr v-for="(item, index) in pagedItems"
            :key="index"
            :class="{ 'is-selected' : selectedRow === index }">
            <td class="has-vertical-middle">{{ item.itemNo }}</td>
            <td class="has-vertical-middle">{{ item.itemDesc }}</td>
            <td class="has-vertical-middle">{{ item.itemType }}</td>
            <td class="is-comment-input">
              <input class="input"
                v-model="comments[index + ((filter.pageIndex - 1) * filter.pageSize)].comment">
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </article>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { TableColumn } from '@/classes'
// import _orderBy from 'lodash/orderBy'

export default {
  name: 'OrmItemComments',
  components: {
    BulmaTable
  },
  props: {
    value: null,
    quote: null
  },
  data() {
    return {
      innerValue: this.value,
      isTableLoading: false,
      selectedRow: null,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 12
      },
      items: [],
      comments: []
    }
  },
  computed: {
    columns() {
      return [
        new TableColumn('Item No.', 'itemNo', true, 'asc', 'left'),
        new TableColumn('Description', 'itemDesc', false, 'desc', 'left'),
        new TableColumn('Type', 'itemType', false, 'desc', 'left'),
        new TableColumn('Comment', 'comment', true, 'desc', 'left')
      ]
    },
    totalRows() {
      return this.activeItems.length
    },
    activeItems() {
      // return _orderBy(this.items.filter(i => !i.deleted), ['itemType', 'itemDesc'], ['asc', 'asc'])
      // return _orderBy(this.items.filter(i => !i.deleted), ['itemDesc'], ['asc'])
      return this.items
    },
    pagedItems() {
      return this.activeItems.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    }
  },
  watch: {
    // innerValue: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // },
    comments: {
      handler: function(newVal, oldVal) {
        this.innerValue.itemComments = this.comments.filter(c => c.comment !== '')
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.items = this.quote.labours.concat(this.quote.others, this.quote.parts, this.quote.opgs, this.quote.miscs, this.quote.sublets).filter(i => !i.deleted)
    this.innerValue = this.value
    this.comments = new Array(this.items.length)
    for (var i = 0; i < this.items.length; i++) {
      this.comments.splice(i, 1, { quoteItemId: this.items[i].quoteItemId, comment: '' })
    }
  },
  mounted() {},
  methods: {
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    sort() {}
  }
}
</script>

<style lang="scss" scoped>
td.is-comment-input {
  min-width: 20rem;
}
</style>
