<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>{{ modalTitle }}</span>
      </div>
    </header>
    <section class="modal-card-body">
      <orm-vehicle-location-reason v-show="componentStatuses[0]"
        v-model="sendQuoteObject" />
      <orm-item-comments v-if="value && sendQuoteType === ormSendQuoteTypes.Quote"
        v-show="componentStatuses[1]"
        v-model="sendQuoteObject"
        :quote="value" />
      <image-select v-if="value"
        v-show="componentStatuses[components.length === 3 ? 2 : 1]"
        v-model="selectedImageIds"
        :quote-id="value.quoteId" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button v-show="activeComponent > 0"
        class="button is-success tooltip"
        @click="previous()"
        data-tooltip="Previous">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-left" />
        </span>
      </button>
      <button v-show="activeComponent < components.length - 1"
        class="button is-success tooltip"
        @click="next()"
        data-tooltip="Next"
        :disabled="isNextDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-right" />
        </span>
      </button>
      <button v-show="activeComponent === components.length - 1"
        class="button is-success tooltip"
        @click="submit()"
        data-tooltip="Submit"
        :disabled="isSubmitDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Submit</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { EventHubTypes, OrmSendQuoteTypes } from '@/enums'
import { OrmSendQuoteModel } from '@/classes'
import { OrmMessageService } from '../services'
import { QuoteNotificationMixin } from '../mixins'
import OrmVehicleLocationReason from './OrmVehicleLocationReason'
import OrmItemComments from './OrmItemComments'
// import OrmInformTotalLoss from './OrmInformTotalLoss'
// import OrmRequestInspection from './OrmRequestInspection'
import ImageSelect from '@/components/ImageSelect'

export default {
  name: 'OrmSendQuoteModal',
  components: {
    BaseModalEx,
    OrmVehicleLocationReason,
    OrmItemComments,
    // OrmInformTotalLoss,
    // OrmRequestInspection,
    ImageSelect
  },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    sendQuoteType: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    // iconName: {
    //   type: String,
    //   default: 'mdi-cancel'
    // },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      modalTitle: '',
      iconName: 'mdi-upload',
      sendQuoteObject: null,
      selectedImageIds: [],
      isImageAttachActive: false,
      isItemCommentsActive: false,
      componentStatuses: [true, false],
      componentEnabled: [true, true],
      components: ['OrmVehicleLocationReason', 'OrmItemComments', 'ImageSelect'],
      activeComponent: 0
    }
  },
  validations: {},
  computed: {
    isSubmitDisabled() {
      let disabled = false
      if (this.sendQuoteObject.sendQuoteType === OrmSendQuoteTypes.Quote) {
        disabled = false
      } else if (this.sendQuoteObject.sendQuoteType === OrmSendQuoteTypes.RequestInspection) {
        disabled = !this.sendQuoteObject.inspectionReason
      } else if (this.sendQuoteObject.sendQuoteType === OrmSendQuoteTypes.TotalLoss) {
        disabled = !this.sendQuoteObject.totalLossReason || !this.sendQuoteObject.vehicleSuburb
      }
      return disabled
    },
    isNextDisabled() {
      if (this.activeComponent === 0) {
        return this.isSubmitDisabled
      }
    },
    ormSendQuoteTypes() {
      return OrmSendQuoteTypes
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    selectedImageIds: {
      handler: function(newVal, oldVal) {
        this.sendQuoteObject.imageIds = this.selectedImageIds.map(i => i.quoteImageId)
      },
      deep: true
    }
  },
  created() {
    if (this.sendQuoteType === OrmSendQuoteTypes.Quote) {
      this.components = ['OrmVehicleLocationReason', 'OrmItemComments', 'ImageSelect']
      this.componentStatuses = [true, false, false]
      this.modalTitle = 'Send Quote'
      this.iconName = 'mdi-upload'
      this.componentEnabled[1] = true
    } else if (this.sendQuoteType === OrmSendQuoteTypes.TotalLoss) {
      this.components = ['OrmVehicleLocationReason', 'ImageSelect']
      this.componentStatuses = [true, false]
      this.modalTitle = 'Inform Total Loss'
      this.iconName = 'mdi-car-off'
    } else if (this.sendQuoteType === OrmSendQuoteTypes.RequestInspection) {
      this.components = ['OrmVehicleLocationReason', 'ImageSelect']
      this.componentStatuses = [true, false]
      this.modalTitle = 'Request Inspection'
      this.iconName = 'mdi-eye-check-outline'
    }
    this.sendQuoteObject = new OrmSendQuoteModel()
    this.sendQuoteObject.quoteId = this.value.quoteId
    this.sendQuoteObject.sendQuoteType = this.sendQuoteType
    this.sendQuoteObject.vehicleLocation = 'Repairer'
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    previous() {
      if (this.activeComponent > 0) {
        this.activeComponent--
        console.log(this.activeComponent)
        this.componentStatuses[this.activeComponent + 1] = false
        this.componentStatuses[this.activeComponent] = true
      }
    },
    next() {
      if (this.activeComponent < this.components.length - 1) {
        this.activeComponent++
        console.log(this.activeComponent)
        this.componentStatuses[this.activeComponent - 1] = false
        this.componentStatuses[this.activeComponent] = true
      }
    },
    cancel() {
      this.$emit('close')
    },
    async submit() {
      this.$emit('close')
      this.$showSpinner('Submitting')
      const result = await OrmMessageService.submitQuote(this.sendQuoteObject)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast(`${this.modalTitle} submitted`)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
