<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div v-if="innerValue.sendQuoteType !== ormSendQuoteTypes.RequestInspection"
          class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Vehicle located at</label>
              <div class="select">
                <select autofocus
                  v-model="innerValue.vehicleLocation">
                  <option value="Repairer">Repairer</option>
                  <option value="Customer">Customer</option>
                  <option value="RMC">RMC</option>
                </select>
              </div>
            </div>
            <div class="field">
              <div class="field">
                <div class="control is-pulled-right">
                  <input class="is-checkradio is-small is-rtl"
                    id="chk-orm-google"
                    name="chk-orm-google"
                    value="GoogleSuburbAutocomplete"
                    type="radio"
                    v-model="mapSource">
                  <label for="chk-orm-google">Google</label>
                  <input class="is-checkradio is-small is-rtl"
                    id="chk-orm-mapbox"
                    name="chk-orm-mapbox"
                    value="MapboxSuburbAutocomplete"
                    type="radio"
                    v-model="mapSource">
                  <label for="chk-orm-mapbox">Mapbox</label>
                </div>
              </div>
              <div class="field">
                <label class="label">Suburb</label>
                <div class="control is-expanded">
                  <!-- <google-suburb-autocomplete v-model="suburb"></google-suburb-autocomplete> -->
                  <!-- <mapbox-suburb-autocomplete v-model="suburb"></mapbox-suburb-autocomplete> -->
                  <component :is="mapSource"
                    v-model="innerValue.vehicleSuburb" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="innerValue.sendQuoteType === ormSendQuoteTypes.TotalLoss"
          class="field">
          <label class="label">Total Loss Reason</label>
          <textarea class="textarea"
            rows="5"
            v-model="innerValue.totalLossReason" />
        </div>
        <div
          v-if="innerValue.sendQuoteType === ormSendQuoteTypes.RequestInspection"
          class="field">
          <label class="label">Request Inspection Reason</label>
          <textarea
            class="textarea"
            rows="5"
            v-model="innerValue.inspectionReason" />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { GoogleSuburbAutocomplete, MapboxSuburbAutocomplete } from '@/components/Address'
import { OrmSendQuoteTypes } from '@/enums'

export default {
  name: 'OrmVehicleLocationReason',
  components: {
    GoogleSuburbAutocomplete,
    MapboxSuburbAutocomplete
  },
  props: {
    value: null
  },
  data() {
    return {
      innerValue: this.value,
      suburb: '',
      mapSource: 'GoogleSuburbAutocomplete'
    }
  },
  computed: {
    ormSendQuoteTypes() {
      return OrmSendQuoteTypes
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {},
  methods: {}
}
</script>
