<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        Inform Total Loss
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrmInformTotalLoss',
  components: {},
  props: {
    value: null
  },
  data() {
    return {
      innerValue: this.value
    }
  },
  computed: {},
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {},
  methods: {}
}
</script>
