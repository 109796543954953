<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <!-- <slot name="text-title"></slot> -->
        <span>Decline Quote Request</span>
      </div>
    </header>
    <section class="modal-card-body">
      <orm-vehicle-location-reason v-model="declineQuoteRequestModel" />
      <div class="tile is-ancestor is-parent">
        <div class="tile is-parent is-vertical">
          <article class="tile is-child box detail-page-tile">
            <div class="field">
              <label class="label">Decline Reason</label>
              <textarea class="textarea"
                rows="5"
                autofocus
                v-model="declineQuoteRequestModel.declineReason" />
            </div>
          </article>
        </div>
      </div>
    </section>
    <footer
      class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button
        class="button is-success tooltip"
        @click="submitDeclineQuoteRequest()"
        data-tooltip="Submit"
        :disabled="!declineQuoteRequestModel.declineReason">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Submit</span>
      </button>
      <button
        class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { OrmDeclineQuoteRequestModel } from '@/classes'
import { EventHubTypes } from '@/enums'
import { OrmMessageService } from '../services'
import { QuoteNotificationMixin } from '../mixins'
import OrmVehicleLocationReason from './OrmVehicleLocationReason'

export default {
  name: 'OrmDeclineQuoteRequestModal',
  components: {
    BaseModalEx,
    OrmVehicleLocationReason
  },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-cancel'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      declineQuoteRequestModel: null
    }
  },
  validations: {},
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.declineQuoteRequestModel = new OrmDeclineQuoteRequestModel()
    this.declineQuoteRequestModel.vehicleLocation = 'Repairer'
    this.declineQuoteRequestModel.quoteId = this.value.quoteId
    this.declineQuoteRequestModel.declineReason = ''
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    async submitDeclineQuoteRequest() {
      this.$emit('close')
      this.$showSpinner('Submitting')
      const result = await OrmMessageService.submitDeclineQuoteRequest(this.declineQuoteRequestModel)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast('Decline Request For Quote submitted')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
